exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-app-template-tsx": () => import("./../../../src/templates/app-template.tsx" /* webpackChunkName: "component---src-templates-app-template-tsx" */),
  "component---src-templates-landing-template-tsx": () => import("./../../../src/templates/landing-template.tsx" /* webpackChunkName: "component---src-templates-landing-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/news-template.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */),
  "component---src-templates-privacy-policy-template-tsx": () => import("./../../../src/templates/privacy-policy-template.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-template-tsx" */)
}

